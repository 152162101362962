var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exam-result-items-table"},[(_vm.assessment.typeSlug.startsWith('enem'))?_c('div',{staticClass:"result-filter"},[_c('s-select',{staticClass:"result-filter__select",attrs:{"allow-empty":false,"disabled":_vm.loading,"label":_vm.$t('pages.finalResult.items.knowledgeAreaFilter'),"loading":_vm.loading,"options":_vm.knowledgeAreas},on:{"select":function($event){return _vm.selectArea($event)}},model:{value:(_vm.selectedArea),callback:function ($$v) {_vm.selectedArea=$$v},expression:"selectedArea"}}),_c('s-select',{staticClass:"result-filter__select",attrs:{"allow-empty":false,"disabled":_vm.loading,"label":_vm.$t('pages.finalResult.items.lectureFilter'),"loading":_vm.loading,"options":_vm.categories},on:{"select":function($event){return _vm.selectCategory($event)}},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1):_vm._e(),_c('DataTable',{attrs:{"active-row":_vm.selectedItem,"data":_vm.itemsPerformanceResult,"density":_vm.dataTableDensity,"fields":_vm.visibleFields,"loading":_vm.loading,"row-cursor":"pointer"},on:{"click-row":function($event){return _vm.selectRow($event)}},scopedSlots:_vm._u([{key:"order",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getFormattedOrder(row.order))+" ")]}},{key:"markedOption",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"item-answer"},[(!_vm.isEnem2024OrAfter)?_c('p',[_vm._v(" "+_vm._s(_vm.getOptionByOrder(row.markedOption))+" ")]):_vm._e(),_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(row.markedOption === -1 ? 'Marcação inválida' : ''),expression:"row.markedOption === -1 ? 'Marcação inválida' : ''"}],attrs:{"stroke":row.markedOption === row.correctOption
            ? _vm.$tokens.color_success
            : _vm.$tokens.color_danger,"type":row.markedOption === row.correctOption ? 'check' : 'x'}})],1)}},{key:"correctOption",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getOptionByOrder(row.correctOption))+" ")]}},{key:"globalGrade",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPercentage(row.globalGrade))+"% ")]}},{key:"chapter",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.hasAlternativeChapter ? 'Aula' : 'Capítulo')+" "+_vm._s(row.chapter)+" ")]}},{key:"alternativeChapter",fn:function(ref){
            var row = ref.row;
return [_vm._v(" Módulo "+_vm._s(row.alternativeChapter)+" ")]}}])},[_c('template',{slot:"actions"},[_c('s-button',{staticClass:"bttn",attrs:{"icon-right":"arrow-right","variation":"tertiary"}})],1)],2),(_vm.sidebarVisible)?_c('ItemResultDrawer',{key:"items-sidebar",attrs:{"exam-id":_vm.examId,"has-next-item":_vm.hasNextItem,"has-previous-item":_vm.hasPreviousItem,"item-id":_vm.selectedItem.itemId,"item-order":_vm.selectedItem.order,"marked-option":_vm.selectedItem.markedOption},on:{"back":function($event){return _vm.back()},"close":function($event){return _vm.closeItemSidebar()},"next":function($event){return _vm.next()}},scopedSlots:_vm._u([{key:"info-header",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"drawer__info-header"},[(!_vm.isRankedAssessment)?_c('Info',{attrs:{"label":_vm.categoryType,"text":("<h5>" + (item.headingTopic) + "</h5>")}}):_vm._e(),(!_vm.isRankedAssessment && item.content)?_c('Info',{attrs:{"label":"Prática de linguagem","text":("<h5>" + (item.content) + "</h5>")}}):_vm._e(),(_vm.isRankedAssessment)?_c('Info',{attrs:{"label":("Conteúdo " + (_vm.hasAlternativeChapter ? 'Pré-Uni' : '')),"text":("<h5>" + (item.lecture) + " - " + (_vm.hasAlternativeChapter ? 'Aula' : 'Capítulo') + " " + (item.chapter) + "</h5>")}}):_vm._e(),(_vm.hasAlternativeChapter)?_c('Info',{attrs:{"label":"Conteúdo Pré-Vest","text":("<h5>" + (item.lecture) + " - Módulo " + (item.alternativeChapter) + "</h5>")}}):_vm._e()],1)]}},{key:"info-body",fn:function(ref){
            var item = ref.item;
return [(!_vm.isRankedAssessment)?_c('Info',{attrs:{"label":"Habilidade","text":((item.skillBnccCode) + " · " + (item.skillName))}}):_vm._e(),(item.learningExpectation)?_c('Info',{attrs:{"label":_vm.isGradedByIrt
            ? 'Expectativa de aprendizagem'
            : 'Aprendizagem essencial',"text":("" + (item.learningExpectation))}}):_vm._e(),(_vm.selectedItem.globalGrade)?_c('Info',{staticClass:"drawer__info-footer",attrs:{"label":"Acerto nacional","text":("<h5>" + (_vm.getPercentage(_vm.selectedItem.globalGrade)) + "%</h5>")}}):_vm._e()]}}],null,false,225169088)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }