<template>
  <div class="exam-result-items-table">
    <div
      v-if="assessment.typeSlug.startsWith('enem')"
      class="result-filter"
    >
      <s-select
        v-model="selectedArea"
        :allow-empty="false"
        class="result-filter__select"
        :disabled="loading"
        :label="$t('pages.finalResult.items.knowledgeAreaFilter')"
        :loading="loading"
        :options="knowledgeAreas"
        @select="selectArea($event)"
      />

      <s-select
        v-model="selectedCategory"
        :allow-empty="false"
        class="result-filter__select"
        :disabled="loading"
        :label="$t('pages.finalResult.items.lectureFilter')"
        :loading="loading"
        :options="categories"
        @select="selectCategory($event)"
      />
    </div>
    <DataTable
      :active-row="selectedItem"
      :data="itemsPerformanceResult"
      :density="dataTableDensity"
      :fields="visibleFields"
      :loading="loading"
      row-cursor="pointer"
      @click-row="selectRow($event)"
    >
      <template
        slot="order"
        slot-scope="{ row }"
      >
        {{ getFormattedOrder(row.order) }}
      </template>

      <div
        slot="markedOption"
        slot-scope="{ row }"
        class="item-answer"
      >
        <p v-if="!isEnem2024OrAfter">
          {{ getOptionByOrder(row.markedOption) }}
        </p>

        <Icon
          v-tooltip="row.markedOption === -1 ? 'Marcação inválida' : ''"
          :stroke="
            row.markedOption === row.correctOption
              ? $tokens.color_success
              : $tokens.color_danger
          "
          :type="row.markedOption === row.correctOption ? 'check' : 'x'"
        />
      </div>

      <template
        slot="correctOption"
        slot-scope="{ row }"
      >
        {{ getOptionByOrder(row.correctOption) }}
      </template>

      <template
        slot="globalGrade"
        slot-scope="{ row }"
      >
        {{ getPercentage(row.globalGrade) }}%
      </template>

      <template
        slot="chapter"
        slot-scope="{ row }"
      >
        {{ hasAlternativeChapter ? 'Aula' : 'Capítulo' }} {{ row.chapter }}
      </template>

      <template
        slot="alternativeChapter"
        slot-scope="{ row }"
      >
        Módulo {{ row.alternativeChapter }}
      </template>
      <template
        slot="actions"
      >
        <s-button
          class="bttn"
          icon-right="arrow-right"
          variation="tertiary"
        />
      </template>
    </DataTable>

    <ItemResultDrawer
      v-if="sidebarVisible"
      key="items-sidebar"
      :exam-id="examId"
      :has-next-item="hasNextItem"
      :has-previous-item="hasPreviousItem"
      :item-id="selectedItem.itemId"
      :item-order="selectedItem.order"
      :marked-option="selectedItem.markedOption"
      @back="back()"
      @close="closeItemSidebar()"
      @next="next()"
    >
      <template
        slot="info-header"
        slot-scope="{ item }"
      >
        <div class="drawer__info-header">
          <Info
            v-if="!isRankedAssessment"
            :label="categoryType"
            :text="`<h5>${item.headingTopic}</h5>`"
          />

          <Info
            v-if="!isRankedAssessment && item.content"
            label="Prática de linguagem"
            :text="`<h5>${item.content}</h5>`"
          />

          <Info
            v-if="isRankedAssessment"
            :label="`Conteúdo ${hasAlternativeChapter ? 'Pré-Uni' : ''}`"
            :text="
              `<h5>${item.lecture} - ${
                hasAlternativeChapter ? 'Aula' : 'Capítulo'
              } ${item.chapter}</h5>`
            "
          />

          <Info
            v-if="hasAlternativeChapter"
            label="Conteúdo Pré-Vest"
            :text="
              `<h5>${item.lecture} - Módulo ${item.alternativeChapter}</h5>`
            "
          />
        </div>
      </template>

      <template
        slot="info-body"
        slot-scope="{ item }"
      >
        <Info
          v-if="!isRankedAssessment"
          label="Habilidade"
          :text="`${item.skillBnccCode} · ${item.skillName}`"
        />

        <Info
          v-if="item.learningExpectation"
          :label="
            isGradedByIrt
              ? 'Expectativa de aprendizagem'
              : 'Aprendizagem essencial'
          "
          :text="`${item.learningExpectation}`"
        />

        <Info
          v-if="selectedItem.globalGrade"
          class="drawer__info-footer"
          label="Acerto nacional"
          :text="`<h5>${getPercentage(selectedItem.globalGrade)}%</h5>`"
        />
      </template>
    </ItemResultDrawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import isNumber from 'lodash/isNumber'
import DataTable from '@/components/DataTable'
import ItemResultDrawer from '@/components/ItemResultDrawer'
import Info from '@/components/Info'
import mediaQueries from '@/mixins/mediaQueries'

const INVISIBLE_STUDENT_ANSWER_YEAR = 2024

export default {
  name: 'ExamResultItemsTable',

  components: {
    DataTable,
    ItemResultDrawer,
    Info,
  },

  mixins: [mediaQueries],

  props: {
    isPartial: Boolean,

    examId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      fields: [],
      loading: false,
      selectedCategory: 'Todas',
      selectedArea: 'Geral',
      hasError: false,
      selectedItem: {
        order: -1,
        id: 0,
      },
      itemsPerformance: [],
      sidebarVisible: false,
    }
  },

  computed: {
    ...mapGetters([
      'isRankedAssessment',
      'assessment',
      'isGradedByIrt',
      'exam',
      'applicationResponse',
    ]),

    isEnem2024OrAfter() {
      return (this.assessment.year >= INVISIBLE_STUDENT_ANSWER_YEAR) && this.assessment.typeSlug.startsWith('enem')
    },

    possibleFields() {
      return [
        {
          value: 'order',
          text: 'Questão',
          sortable: true,
          visible: true,
        },
        {
          value: this.isPartial || this.assessment.typeSlug.startsWith('enem')
            ? 'lecture'
            : 'category',
          text: 'Disciplina',
          sortable: true,
          visible: this.isRankedAssessment && !this.mq_s,
        },
        {
          value: 'markedOption',
          text: 'Resposta',
          sortable: true,
          visible: true,
        },
        {
          value: 'correctOption',
          text: 'Gabarito',
          sortable: true,
          visible: !this.isEnem2024OrAfter,
        },
        {
          value: 'globalGrade',
          text: 'Acerto nacional',
          sortable: true,
          visible: !this.isPartial && !this.mq_m,
        },
        {
          value: 'chapter',
          text: 'Contéudo',
          sortable: true,
          visible: this.isRankedAssessment && !this.mq_m,
        },
      ]
    },

    visibleFields() {
      return this.fields.length
        ? this.fields.filter((field) => field.visible)
        : this.possibleFields.filter((field) => field.visible)
    },

    knowledgeAreas() {
      return [
        'Geral',
        ...new Set(this.itemsPerformance
          .map(({ examType }) => examType)
          .sort()),
      ]
    },

    categories() {
      return [
        'Todas',
        ...new Set(this.itemsPerformance
          .filter(({ examType }) => this.selectedArea === this.knowledgeAreas[0]
            || this.selectedArea === examType)
          .map(({ lecture }) => lecture)
          .sort()),
      ]
    },

    itemsPerformanceResult() {
      return this.itemsPerformance
        .filter((item) => this.selectedCategory === this.categories[0]
          || item.lecture === this.selectedCategory)
        .filter((item) => this.selectedArea === this.knowledgeAreas[0]
          || item.examType === this.selectedArea)
    },

    dataTableDensity() {
      if (this.mq_xs) {
        return 'x-small'
      }

      return this.mq_m ? 'small' : 'large'
    },

    hasAlternativeChapter() {
      const { alternativeChapter } = this.itemsPerformance[0]

      return alternativeChapter
    },

    isRowSelected() {
      return this.selectedItem.order >= 0
    },

    categoryType() {
      return this.exam.name === 'Matemática'
        ? 'Unidade temática'
        : 'Campo de atuação'
    },

    hasPreviousItem() {
      return this.selectedItem.order > 1
    },

    hasNextItem() {
      return this.selectedItem.order < this.itemsPerformance.length
    },
  },

  watch: {
    $mq() {
      this.fields = this.getTableFields()
    },
  },

  async created() {
    const { applicationId, examId } = this.$route.params
    await this.getApplicationResponse({
      applicationId,
      examId,
    })
    await this.load()
  },

  methods: {
    ...mapActions([
      'getApplicationResponse',
      'getPartialItemsPerformance',
      'getPerformanceByItemsResult',
    ]),

    async load() {
      this.loading = true
      const { assessmentId, applicationId, examId } = this.$route.params
      try {
        if (this.isPartial) {
          await this.loadPartialItemsPerformance({
            applicationId,
            examId,
          })
        } else {
          await this.loadFinalItemsPerformance({
            assessmentId,
            examId,
          })
        }
        this.fields = this.getTableFields()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.loading = false
      }
    },

    async loadPartialItemsPerformance() {
      this.itemsPerformance = await this.getPartialItemsPerformance(
        this.applicationResponse.id,
      )
    },

    async loadFinalItemsPerformance({ assessmentId, examId }) {
      this.itemsPerformance = await this.getPerformanceByItemsResult({
        assessmentId,
        examId,
        slug: this.assessment.typeSlug,
      })
      this.itemsPerformance = this.sortItemsPerformanceByOrder()
    },

    sortItemsPerformanceByOrder() {
      return this.itemsPerformance
        .map((performance) => ({
          ...performance,
          order: this.isRankedAssessment
            ? performance.order
            : performance.studentOrder,
        }))
        .sort((a, b) => a.order - b.order)
    },
    selectArea(area) {
      this.selectedArea = area || this.knowledgeAreas[0]
      this.selectCategory()
    },
    selectCategory(category) {
      this.selectedCategory = category || this.categories[0]
    },
    getTableFields() {
      const fields = this.possibleFields

      if (this.hasAlternativeChapter) {
        const chapterField = fields.find((field) => field.value === 'chapter')
        const prefix = chapterField.text
        chapterField.text = `${prefix} Pré-Uni`

        fields.push({
          value: 'alternativeChapter',
          text: `${prefix} Pré-Vest`,
          sortable: true,
          visible: !this.mq_m,
        })
      }

      return fields
    },

    showErrorMessage(error) {
      this.$toasted.global.error({
        message: 'Ocorreu um erro ao carregar seu desempenho nas questões.',
        errorCode: error.response ? error.response.status : '',
      })
    },

    selectRow(item) {
      if (this.loading || this.hasError) {
        return
      }
      this.selectedItem = item
      const { itemsPerformance } = this
      this.showItemSidebar({
        item,
        itemsPerformance,
      })
    },

    getFormattedOrder(order) {
      const numberLength = 2

      return order ? order.toString().padStart(numberLength, '0') : ''
    },

    getOptionByOrder(optionOrder) {
      const options = ['A', 'B', 'C', 'D', 'E']

      if (!isNumber(optionOrder) || !options[optionOrder]) {
        return ''
      }

      return options[optionOrder]
    },

    getPercentage(grade) {
      const percentageModifier = 100

      return Math.round(percentageModifier * grade)
    },

    showItemSidebar() {
      if (this.loading) {
        return
      }
      this.sidebarVisible = true
    },

    closeItemSidebar() {
      this.selectedItem = {
        order: -1,
        id: 0,
      }
      this.sidebarVisible = false
    },

    back() {
      this.selectedItem = this.itemsPerformance.find(
        (item) => item.order === this.selectedItem.order - 1,
      )
    },

    next() {
      this.selectedItem = this.itemsPerformance.find(
        (item) => item.order === this.selectedItem.order + 1,
      )
    },
  },
}
</script>

<style lang="sass" scoped>
$dropdown-max-height: 200px

.exam-result-items-table

  .item-answer
    display: flex
    align-items: center
    justify-content: flex-start

    span
      margin-right: 8px

  ::v-deep .sas-dropdown
    width: 100%

    .sas-dropdown-menu
      width: 100%
      max-height: $dropdown-max-height
      overflow-y: auto
      justify-content: unset

  .result-filter
    display: flex
    flex-wrap: wrap

    &__select
      min-width: 250px
      margin-right: 15px
      margin-bottom: 20px

      +mq-s
        width: 100%

  .drawer

    &__info-header
      display: flex

      +mq-s
        display: block

      .info-2 ~ .info-2
        margin-left: 32px

        +mq-s
          margin-left: 0
          margin-top: 16px

    &__info-footer
      margin-top: 32px
.bttn
  width: 36px
</style>
