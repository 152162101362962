var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Drawer',{staticClass:"item-drawer",attrs:{"next-button-enabled":_vm.hasNextItem,"overlay-enabled":false,"padding":{ body: '24px 0' },"pagination-enabled":"","previous-button-enabled":_vm.hasPreviousItem,"size":_vm.mq_l ? '100%' : '50%',"title":("<h6>Questão " + _vm.itemOrder + "</h6>")},on:{"close":function($event){return _vm.$emit('close')},"next":function($event){return _vm.$emit('next')},"previous":function($event){return _vm.$emit('back')}}},[(!_vm.loading && _vm.markedOption >= -1)?_c('SBadge',{attrs:{"slot":"infos","status":_vm.badgeStatus},slot:"infos"},[_c('Info',{attrs:{"icon":{
        name: _vm.badgeIcon,
        color: _vm.badgeColor,
      },"text":_vm.getBadgeStatusText()}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"item-drawer__body --loading"},[_c('Spinner')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"item-drawer__body"},[(_vm.$scopedSlots['info-header'])?_c('div',{staticClass:"item-drawer__item-info"},[_vm._t("info-header",null,{"item":_vm.item})],2):_vm._e(),(_vm.$scopedSlots['info-body'])?_c('div',{staticClass:"item-drawer__item-info"},[_vm._t("info-body",null,{"item":_vm.item})],2):_vm._e(),(_vm.item.embedHtml)?_c('div',{staticClass:"item-drawer__section"},[_c('h5',{staticClass:"item-drawer__section-title"},[_vm._v(" Vídeo de resolução ")]),_c('div',{staticClass:"item-drawer__player",domProps:{"innerHTML":_vm._s(_vm.item.embedHtml)}})]):_vm._e(),_c('div',{staticClass:"item-drawer__section"},[_c('SasButton',{staticClass:"item-drawer__section-title",attrs:{"theme":"float"},on:{"click":function($event){_vm.wordingVisible = !_vm.wordingVisible}}},[_c('Icon',{attrs:{"type":_vm.wordingVisible ? 'chevron-down' : 'chevron-right'}}),_vm._v(" Enunciado ")],1),(_vm.wordingVisible)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.formattedWording)}}):_vm._e()],1),_c('div',{staticClass:"item-drawer__section"},[_c('SasButton',{staticClass:"item-drawer__section-title",attrs:{"theme":"float"},on:{"click":function($event){_vm.optionsVisible = !_vm.optionsVisible}}},[_c('Icon',{attrs:{"type":_vm.optionsVisible ? 'chevron-down' : 'chevron-right'}}),_vm._v(" Alternativas ")],1),(_vm.optionsVisible)?_c('div',_vm._l((_vm.options),function(ref,index){
      var option = ref.option;
return _c('ExamItemOption',{key:index,class:{
            'answer': parseInt(_vm.item.answerKey) === index,
            'correct-answer': _vm.isCorrectAnswer && _vm.isIndexMarkedOption(index),
            'wrong-answer': _vm.isWrongAnswer && _vm.isIndexMarkedOption(index),
          },attrs:{"body":option,"letter":_vm.getOptionByOrder(index),"order":index + 1}})}),1):_vm._e()],1),_c('div',{staticClass:"item-drawer__section"},[_c('SasButton',{staticClass:"item-drawer__section-title",attrs:{"theme":"float"},on:{"click":function($event){_vm.explanationVisible = !_vm.explanationVisible}}},[_c('Icon',{attrs:{"type":_vm.iconType}}),_vm._v(" Resolução descrita ")],1),(_vm.explanationVisible)?_c('div',{staticClass:"item-drawer__explanation",domProps:{"innerHTML":_vm._s(_vm.item.explanation)}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }