/* eslint-disable no-param-reassign */
export default {
  mounted() {
    this.overrideStyles()
  },

  updated() {
    this.overrideStyles()
  },

  methods: {
    overrideStyles() {
      const { item } = this.$refs

      if (!item) {
        return
      }

      const paragraphs = [...item.getElementsByTagName('p')]
      const spans = [...item.getElementsByTagName('span')]
      const mediumScreen = 768

      paragraphs.forEach((p) => {
        if (p.style.fontSize === '16px' || p.style.fontSize === '14px') {
          if (window.screen.width > mediumScreen) {
            p.style.fontSize = '18px'
          } else {
            p.style.fontSize = '16px'
          }
        }
      })

      spans.forEach((span) => {
        if (span.style.fontSize === '16px' || span.style.fontSize === '14px') {
          if (window.screen.width > mediumScreen) {
            span.style.fontSize = '18px'
          } else {
            span.style.fontSize = '16px'
          }
        }
      })
    },
  },
}
