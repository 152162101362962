<template>
  <Drawer
    class="item-drawer"
    :next-button-enabled="hasNextItem"
    :overlay-enabled="false"
    :padding="{ body: '24px 0' }"
    pagination-enabled
    :previous-button-enabled="hasPreviousItem"
    :size="mq_l ? '100%' : '50%'"
    :title="`<h6>Questão ${itemOrder}</h6>`"
    @close="$emit('close')"
    @next="$emit('next')"
    @previous="$emit('back')"
  >
    <SBadge
      v-if="!loading && markedOption >= -1"
      slot="infos"
      :status="badgeStatus"
    >
      <Info
        :icon="{
          name: badgeIcon,
          color: badgeColor,
        }"
        :text="getBadgeStatusText()"
      />
    </SBadge>

    <div
      v-show="loading"
      class="item-drawer__body --loading"
    >
      <Spinner />
    </div>

    <div
      v-show="!loading"
      class="item-drawer__body"
    >
      <div
        v-if="$scopedSlots['info-header']"
        class="item-drawer__item-info"
      >
        <slot
          :item="item"
          name="info-header"
        />
      </div>

      <div
        v-if="$scopedSlots['info-body']"
        class="item-drawer__item-info"
      >
        <slot
          :item="item"
          name="info-body"
        />
      </div>

      <div
        v-if="item.embedHtml"
        class="item-drawer__section"
      >
        <h5 class="item-drawer__section-title">
          Vídeo de resolução
        </h5>

        <div
          class="item-drawer__player"
          v-html="item.embedHtml"
        />
      </div>

      <div class="item-drawer__section">
        <SasButton
          class="item-drawer__section-title"
          theme="float"
          @click="wordingVisible = !wordingVisible"
        >
          <Icon :type="wordingVisible ? 'chevron-down' : 'chevron-right'" />
          Enunciado
        </SasButton>

        <div
          v-if="wordingVisible"
          v-html="item.formattedWording"
        />
      </div>

      <div class="item-drawer__section">
        <SasButton
          class="item-drawer__section-title"
          theme="float"
          @click="optionsVisible = !optionsVisible"
        >
          <Icon :type="optionsVisible ? 'chevron-down' : 'chevron-right'" />
          Alternativas
        </SasButton>

        <div v-if="optionsVisible">
          <ExamItemOption
            v-for="({ option }, index) in options"
            :key="index"
            :body="option"
            :class="{
              'answer': parseInt(item.answerKey) === index,
              'correct-answer': isCorrectAnswer && isIndexMarkedOption(index),
              'wrong-answer': isWrongAnswer && isIndexMarkedOption(index),
            }"
            :letter="getOptionByOrder(index)"
            :order="index + 1"
          />
        </div>
      </div>

      <div class="item-drawer__section">
        <SasButton
          class="item-drawer__section-title"
          theme="float"
          @click="explanationVisible = !explanationVisible"
        >
          <Icon :type="iconType" />

          Resolução descrita
        </SasButton>

        <div
          v-if="explanationVisible"
          class="item-drawer__explanation"
          v-html="item.explanation"
        />
      </div>
    </div>
  </Drawer>
</template>

<script>
import { mapActions } from 'vuex'

import Info from '@/components/Info'
import mediaQueries from '@/mixins/mediaQueries'
import ExamItemOption from '@/components/ExamItemOption'

export default {
  name: 'StudentItemResultDrawer',

  components: {
    Info,
    ExamItemOption,
  },

  mixins: [mediaQueries],

  props: {
    itemOrder: {
      type: Number,
      default: 0,
    },
    itemId: {
      type: [String, Number],
      required: true,
    },
    examId: {
      type: [String, Number],
      required: true,
    },
    markedOption: {
      type: Number,
      // eslint-disable-next-line no-magic-numbers
      default: () => -2,
    },
    hasPreviousItem: {
      type: Boolean,
      required: true,
    },
    hasNextItem: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      wordingVisible: false,
      optionsVisible: false,
      explanationVisible: false,
      item: {},
    }
  },

  computed: {
    options() {
      return JSON.parse(this.item.formattedOptions).map((option) => ({
        option,
      }))
    },

    isCorrectAnswer() {
      return parseInt(this.item.answerKey, 10) === this.markedOption
    },

    isWrongAnswer() {
      return (
        this.markedOption > -1
        && parseInt(this.item.answerKey, 10) !== this.markedOption
      )
    },

    badgeStatus() {
      return this.isCorrectAnswer ? 'success' : 'fail'
    },

    badgeIcon() {
      return this.isCorrectAnswer ? 'check' : 'x'
    },

    badgeColor() {
      return this.isCorrectAnswer
        ? this.$tokens.color_success
        : this.$tokens.color_danger
    },

    iconType() {
      return this.explanationVisible ? 'chevron-down' : 'chevron-right'
    },
  },

  watch: {
    itemOrder() {
      this.load()
    },
  },

  created() {
    this.load()
  },

  methods: {
    ...mapActions(['getExamItem']),

    async load() {
      this.loading = true
      const { examId, itemId } = this
      try {
        this.item = await this.getExamItem({
          examId,
          itemId,
        })
      } catch (error) {
        this.showErrorMessage('Ocorreu um erro ao tentar carregar a questão')
      } finally {
        this.loading = false
      }
    },

    getOptionByOrder(optionOrder) {
      const options = ['A', 'B', 'C', 'D', 'E']
      const hasnoOptionOrder = !optionOrder && optionOrder !== 0

      if (hasnoOptionOrder || optionOrder === -1) {
        return ''
      }

      return options[optionOrder]
    },

    getBadgeStatusText() {
      if (this.isCorrectAnswer) {
        return 'Acertou'
      } if (this.isWrongAnswer) {
        return 'Errou'
      }

      return 'Marcação inválida'
    },

    showErrorMessage(text) {
      this.$toasted.show(`<p>${text}</p>`, {
        position: 'bottom-left',
        duration: 5000,
      })
    },

    getOptionIndex(option) {
      return this.options.findIndex((current) => current.option === option)
    },

    isIndexMarkedOption(index) {
      return index === this.markedOption
    },
  },
}
</script>

<style lang="sass">
$custom-letter-size: 26px

.item-drawer

  .drawer
    background: white

  &__header-actions
    display: flex
    justify-content: space-between

    .badge
      margin-right: 24px

    .btn
      margin-right: 16px

  &__body

    &.--loading
      height: calc(100vh - 120px)
      display: flex
      align-items: center
      justify-content: center

    .info-right__label
      font-size: 14px

  &__item-info
    margin-bottom: 32px
    padding: 0 40px

    .info-block__content

      p
        line-height: inherit

  &__section
    padding: 16px 40px
    border-top: 1px solid $color-ink-lightest

    .--biggest-percentage
      color: $color-primary
      font-weight: 600

    img
      max-width: 100%

    &-title
      margin: 16px 0px
      color: $color-ink-light

      i
        margin-right: 8px

    .item-drawer__section

      h4
        font-size: $font-size-m
        font-weight: $font-weight-bold

    .item-drawer__player

      +mq-l
        position: relative
        padding-bottom: 56.25%

      iframe
        width: 100%
        height: 350px
        border: none
        padding: 16px 0
        -webkit-box-sizing: border-box
        box-sizing: border-box

        +mq-l
          position: absolute
          top: 0
          left: 0
          height: 100%
          padding: 0

    .item-alternative__letter
      width: 24px
      height: 24px
      line-height: 24px

    .item-alternative
      border: unset
      box-shadow: unset
      cursor: default

      &__letter
        width: $custom-letter-size
        height: $custom-letter-size
        line-height: 24px

      &:hover
        box-shadow: unset

    .answer,
    .correct-answer
      border: 1px solid $color_success
      background: transparentize($color_success, 0.85)

      .item-alternative__letter
        background: $color_success
        color: white
        border-color: $color_success

    .wrong-answer
      border: 1px solid $color_danger
      background: transparentize($color_danger, 0.85)

      .item-alternative__letter
        background: $color_danger
        color: white
        border-color: $color_danger

    .item-drawer__explanation

      h4
        font-size: $font-size-m
        font-weight: $font-weight-bold

  &__answer
    display: flex
    justify-content: flex-end
    padding: 0 32px
    background: transparentize($color-ink-light, 0.8)
</style>
