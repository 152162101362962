<template>
  <Box
    :class="[
      'item-alternative',
      { 'is-selected': selected },
    ]"
    elevation-hover="2"
    padding="0"
  >
    <div
      class="item-alternative__body"
      @click="answer(order)"
    >
      <span
        :class="[
          'item-alternative__body__letter',
          { 'is-selected': selected },
        ]"
      >
        {{ letter }}
      </span>

      <div
        ref="item"
        class="item-alternative__body__value"
        v-html="body"
      />
      <div
        v-if="discardedOption"
        class="item-alternative__body__scratch"
      />
    </div>
    <template v-if="hasDiscardedButton && !selected && enableDiscardOption">
      <s-button
        v-if="discardedOption"
        class="item-alternative__button"
        icon-left="edit-3"
        size="large"
        variation="tertiary"
        @click="discardOption(order)"
      >
        {{ $t('exam.examItemOptions.clearOption') }}
      </s-button>

      <s-button
        v-else-if="selected"
        class="item-alternative__button without-action"
        size="large"
        variation="tertiary"
      >
        {{ $t('exam.examItemOptions.selectedOption') }}
      </s-button>

      <s-button
        v-else
        class="item-alternative__button"
        icon-left="edit-3"
        size="large"
        variation="tertiary"
        @click="discardOption(order)"
      >
        {{ $t('exam.examItemOptions.scratchOption') }}
      </s-button>
    </template>

    <sas-spinner
      v-if="selectedOrder === order"
      class="item-alternative__loading"
      size="small"
    />
  </Box>
</template>

<script>
import overrideStyles from '@/mixins/overrideStyles'

export default {
  name: 'ExamItemOption',

  mixins: [overrideStyles],

  props: {
    order: {
      type: [String, Number],
      required: true,
    },
    letter: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    selected: Boolean,
    discardedOption: Boolean,
    hasDiscardedButton: Boolean,
    selectedOrder: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      enableDiscardOption: false,
    }
  },

  methods: {
    discardOption(order) {
      if (!this.selected) {
        this.$emit('discard', order)
      }
    },
    answer(order) {
      if (!this.discardedOption) {
        this.$emit('answer', order)
      }
    },
  },
}
</script>

<style lang="sass">
$linear-gradient: 268.42deg, #E4E4E4 -14.19%, rgba(185, 194, 209, 0) 89.63%
$background-button-item-alternative: linear-gradient($linear-gradient)

.item-alternative
  cursor: pointer
  display: flex
  -webkit-user-select: none
  -moz-user-select: none
  user-select: none
  border: 1px solid transparentize($color-ink-lighter, .65)
  +anim(150ms)
  border-radius: 8px
  box-shadow: 0 0 0 0 $color-primary inset

  &.--disabled
    pointer-events: none !important
    cursor: loading

  &__body
    width: 100%
    display: flex
    position: relative

    &__value
      width: 100%
      padding: 20px 0
      align-self: center
      z-index: 1
      p, span
        letter-spacing: 0.64px !important
        text-align: left !important

    &__scratch
      width: 90%
      border: 1px solid $color-primary-light
      align-self: center
      position: absolute
      left: 10px
      right: 0
      z-index: 2

    &__letter
      flex-shrink: 0
      margin-right: 16px
      display: flex
      justify-content: center
      align-items: center
      margin-left: 16px
      align-self: center
      width: 32px
      height: 32px
      background-color: $color-white
      font-weight: 700
      font-size: $font-size-s
      text-transform: uppercase
      border-radius: 50%
      color: transparentize($color-ink, .3)
      text-align: center
      border: 2px solid transparentize($color-ink-lighter, .4)
      +anim(200ms)

      +mq-s
        width: 28px
        height: 28px
        line-height: 28px

      &.is-selected
        color: $color-white
        +anim(200ms)
        background: $color-primary
        border-color: $color-primary

  &__button
    .without-action
      pointer-events: none

    &.sas-button
      border: none
      outline: none
      cursor: pointer
      border-radius: 8px
      height: auto
      color: $color-ink-lighter
      align-items: center
      background: $background-button-item-alternative

      &:hover
        background: $background-button-item-alternative
      &:focus
        box-shadow: none !important
      &:active
        background-color: rgba($color-ink, .25)

      .sas-button__text
        width: auto
        padding: 10px
        text-align: left
        font-size: 14px

      i
        padding-right: 3px
        padding-top: 5px

  & ~ &
    margin-top: 16px

  &.is-selected
    border: 3px solid $color-primary

    &.--disabled
      pointer-events: none !important
      cursor: loading
    &::after,
    &:active::after
      +anim(200ms)
      background: transparentize($color-primary, .96) !important
      box-shadow: 0 0 0 3px $color-primary inset

    &:hover::after
      +anim(200ms)
      background: transparentize($color-primary, .85)
      box-shadow: 0 0 0 2px $color-primary inset

  &__loading
    position: absolute
    align-self: center
    right: 16px
    border: 3px solid transparentize($color-ink-lighter, .5)
    border-top: 3px solid $color-primary

</style>
